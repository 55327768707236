import { gql } from "@apollo/client";

// this is for the count in SR
export const GET_ATTACHMENTS = gql`
  query getAttachments($parentId: String! , $docType: [String]) {
    attachments(
      where: {
        parentId: { equalTo: $parentId }
        docType: { in: $docType }
        status: { equalTo: "Active" }
      }
    ) {
      edges {
        node {
          fileName
          id
          docType
          name
          objectId
          parentId
          status
          url
          updatedAt
          createdAt
          createdBy {
            id
            objectId
          }
        }
      }
    }
  }
`;


// this is for the restriction while making SR closed

// export const GET_ATTACHMENTS_RESTRICTIONS = gql`
//   query getAttachmentsRestriction($parentId: String! ) {
//     attachments(
//       where: {
//         parentId: { equalTo: $parentId }
//         status: { equalTo: "Active" }
//       }
//     ) {
//       edges {
//         node {
//           fileName
//           id
//           docType
//           name
//           objectId
//           parentId
//           status
//           url
//           updatedAt
//           createdAt
//           createdBy {
//             id
//             objectId
//           }
//         }
//       }
//     }
//   }
// `;



// import { gql } from "@apollo/client";

// export const GET_ATTACHMENTS = gql`
//   query getAttachments($parentId: String!, $module: String , $docType: String) {
//     attachments(
//       where: {
//         parentId: { equalTo: $parentId }
//         module: { equalTo: $module }
//         docType: { equalTo: $docType }
//         status: { equalTo: "Active" }
//       }
//     ) {
//       edges {
//         node {
//           fileName
//           id
//           module
//           docType
//           name
//           objectId
//           parentId
//           status
//           url
//           updatedAt
//           createdAt
//           createdBy {
//             id
//             objectId
//           }
//         }
//       }
//     }
//   }
// `;
