import { gql } from '@apollo/client';
export const GET_SOCIETY_MEMBERS = gql`
  query getSocietyMembers($societyId: ID, $types: [String]) {
    societyMembers(
      where: {
        AND: {
          type: { in: $types }
          society: { have: { objectId: { equalTo: $societyId } } }
        }
      }
    ) {
      edges {
        node {
          id
          objectId
          type
          subtype
          clientFacilities(first:2000){
            edges{
              node{
                objectId
                name
              }
            }
          }
          member {
            ... on User {
              firstName
              lastName
              objectId
              id
              username
              mobileNumber
            }
          }
        }
      }
    }
  }
`;
export const GET_SOCIETY_MEMBER_INFO_BY_USER = gql`
  query getSocietyMembers($societyId: ID, $types: [String],$userId: ID) {
    societyMembers(
      where: {
        AND: {
          type: { in: $types }
          society: { have: { objectId: { equalTo: $societyId } } }
          member: { have: { objectId: { equalTo: $userId } } }
        }
      }
    ) {
      edges {
        node {
          id
          objectId
          type
          subtype
          clientFacilities{
            edges{
              node{
                objectId
                name
                city
                state
                region
                uniqueCode
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_SOCIETY_MEMBER = gql`
  mutation createSocietyMember(
    $societyId: ID!
    $userId: ID!
    $type: String!
    $subtype: String
  ) {
    createSocietyMember(
      input: {
        fields: {
          type: $type
          subtype: $subtype
          society: { link: $societyId }
          member: { link: $userId }
        }
      }
    ) {
      societyMember {
        id
        objectId
      }
    }
  }
`;

export const DELETE_SOCIETY_MEMBER = gql`
  mutation deleteSocietyMember($id: ID!) {
    deleteSocietyMember(input: { id: $id }) {
      societyMember {
        id
        objectId
      }
    }
  }
`;

export const UPDATE_CLIENT_MEMBER_FACILITIES = gql`
  mutation updateClientMemberFacilities($id: ID!, $addFacilityIds: [ID!],$removeFacilityIds: [ID!]) {
    updateSocietyMember(
      input: {
        id: $id
        fields: { clientFacilities: { add:$addFacilityIds,remove:$removeFacilityIds} }
      }
    ) {
      societyMember {
        id
        objectId
        clientFacilities {
          edges {
            node {
              id
              objectId
              name
            }
          }
        }
      }
    }
  }
`;
